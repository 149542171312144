import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Select, Upload, message, notification } from "antd";
import type { UploadProps } from "antd";
import Icon, {
  ArrowLeftOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Locations from "../../../../components/Locations/Locations/Locations";
import { connect, useDispatch } from "react-redux";
import LocationService from "../../../../domain/Location/LocationService";
import InpService from "../../../../domain/ModelFile/InpService";
import PluviometerService from "../../../../domain/Pluviometers/PluviometerService";
import { setDataModelFile } from "../../../../redux/modelFileDuck";
import { AnyAction } from "redux";

const locationService = new LocationService();
const pluviometerService = new PluviometerService();
const inpService = new InpService();
const { Dragger } = Upload;
type Props = {};
function UploadModelFiles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [inpFile, setInpFile] = useState<any[]>([]);
  const [datFiles, setDatFiles] = useState<any[]>([]);
  const [locations, setlocations] = useState<Location[]>([]);
  const [selectLocation, setSelectlocations] = useState<number>();
  const [pluviometers, setPluviometers] = useState<any[]>([]);
  const [selectedPluviometers, setselectedPluviometers] = useState<any[]>([]);
  const [selectedModelType, setSelectedModelType] = useState<any>("WATER");
  // const [uploadProps, setUploadProps] = useState({
  //   ...initialUploadProps,
  // });
  const initialUploadProps: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".inp,.dat",
    showUploadList: false,
    beforeUpload: (file: any) => {
      const nameSplit = file.name.split(".");
      if (file.name.length > 100) {
        messageApi.open({
          type: "error",
          content: `File name ${file.name} exceeds 100 characters`,
        });
        notification.error({
          message: "Error",
          description: `File name ${file.name} exceeds 100 characters`,
        });
        return false;
      }
      const extension = nameSplit[nameSplit.length - 1];
      if (extension === "inp") {
        setInpFile([file]);
      } else if (extension === "dat") {
        if (selectedModelType == "WATER") return;
        setDatFiles((previousState) => [...previousState, file]);
      } else {
        showErrorFile();
      }
      return false;
    },
  };

  const showErrorFile = () => {
    messageApi.open({
      type: "error",
      content: "One of the files selected is not valid",
    });
  };

  const deleteInp = () => {
    setInpFile([]);
  };

  const deleteDat = (fileId: any) => {
    const newArray = datFiles.filter((file) => file.uid !== fileId);
    setDatFiles(newArray);
  };

  const blobToBase64 = async (blob: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        if (reader.result) {
          const result = reader.result as string;
          resolve(result.split(",")[1]);
        } else {
          resolve("");
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFiles = async () => {
    if (inpFile.length > 0) {
      if (!selectLocation) {
        notification.open({
          message: "Error",
          description: "You must select the location",
          type: "error",
        });
        return;
      }
      let data = {} as any;
      if (inpFile[0].isMms) {
        data = {
          inpName: inpFile[0].name,
          mmsId: inpFile[0].mmsId,
          modelType: selectedModelType,
          locationId: selectLocation,
        };
      } else {
        data = {
          inpName: inpFile[0].name,
          inpdata: await blobToBase64(inpFile[0]),
          modelType: selectedModelType,
          locationId: selectLocation,
        };
      }

      if (selectedModelType == "WATER") {
        const oui = JSON.parse(
          localStorage.getItem("security") || ""
        ).organizationId;
        const userId = localStorage.getItem("userId") || "";
        await inpService.uploadInps(userId, oui, data);
        notification.success({
          message: "Successful",
          description: "Files upload successfully",
        });
        navigate(`/upload?selectedTab=MODEL`);
      } else {
        if (datFiles.length == 0) {
          openNotificationNoDat();
          return;
        }

        data.dat = [] as Dat[];
        for (let index = 0; index < datFiles.length; index++) {
          const files = datFiles[index];
          const pluviometers =
            selectedPluviometers.find((pluv) => pluv.uid === files.uid)
              ?.value || [];
          if (pluviometers && pluviometers.length <= 0) {
            notification.open({
              message: "Error",
              description: "All DAT files must have at least one rain gauge",
              type: "error",
            });
            return;
          }
          data.dat.push({
            datName: files.name,
            datData: await blobToBase64(files),
            pluviometers,
          });
        }

        const oui = JSON.parse(
          localStorage.getItem("security") || ""
        ).organizationId;
        const userId = localStorage.getItem("userId") || "";
        await inpService.uploadInps(userId, oui, data);
        notification.success({
          message: "Successful",
          description: "Files upload successfully",
        });
        navigate(`/upload?selectedTab=MODEL`);
      }
    } else {
      openNotification();
    }
  };

  const openNotificationNoDat = () => {
    notification.open({
      message: "Error",
      description: "At least you need to upload one DAT file.",
      type: "error",
    });
  };

  const openNotification = () => {
    notification.open({
      message: "Error",
      description: "You need to select a INP file.",
      type: "error",
    });
  };

  const handleGetLocations = async () => {
    const userId = localStorage.getItem("userId") || "";
    const res = await locationService.getLocationsByUser(userId);
    setlocations(res);
  };

  const handleGetPluviometers = async () => {
    if (selectLocation) {
      const res = await pluviometerService.getPluviometersByLocation(
        selectLocation
      );
      setPluviometers(res);
    }
  };

  const handleClickLocation = async (location: number) => {
    setSelectlocations(location);
  };

  const goToEdditFile = async () => {
    if (inpFile.length > 0) {
      const data = {
        inpName: inpFile[0].name,
        inpdata: await blobToBase64(inpFile[0]),
        modelType: selectedModelType,
        locationId: selectLocation,
        dat: [],
      } as any;
      if (datFiles.length > 0) {
        for (let index = 0; index < datFiles.length; index++) {
          const files = datFiles[index];
          const pluviometers = selectedPluviometers.find(
            (pluv) => pluv.uid === files.uid
          )?.value;
          if (pluviometers && pluviometers.length <= 0) return;
          data.dat.push({
            datName: files.name,
            datData: await blobToBase64(files),
            pluviometers,
          });
        }
      }
      dispatch(setDataModelFile(data) as unknown as AnyAction);
      navigate(`/upload/modelfiles/editFile`);
    }
  };

  const fileSize = (size: any) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${(size / Math.pow(k, i)).toFixed(3)} ${sizes[i]}`;
  };

  function addExtension(inpString: any) {
    if (!inpString.endsWith(".inp")) {
      return inpString + ".inp";
    }
    return inpString;
  }

  useEffect(() => {
    handleGetLocations();
  }, []);

  useEffect(() => {
    handleGetPluviometers();
  }, [selectLocation]);

  useEffect(() => {
    const mmsId = searchParams.get("mmsId");
    const fileName = searchParams.get("fileName");
    if (mmsId && fileName) {
      setInpFile([{ size: 0, name: addExtension(fileName), isMms: true, mmsId }]);
    }
  }, [searchParams]);

  return (
    <div style={{ padding: "0px 3rem" }}>
      <div>
        <Button
          type="text"
          shape="round"
          className="flex items-center"
          style={{ backgroundColor: "#F7F9FA", color: "#434B56" }}
          onClick={() => {
            navigate(`/upload?selectedTab=MODEL`);
          }}
        >
          <ArrowLeftOutlined rev={undefined} /> Back to Model Files
        </Button>
      </div>
      <div className="flex column pt-2">
        <span className="text-title-page">Upload Model Files</span>
        <div
          className="mt-2 flex items-center"
          style={{
            background: "rgba(255, 238, 203, 0.5)",
            height: "40px",
            borderLeft: "solid 8px #FBC261",
          }}
        >
          <span className="ml-2" style={{ color: "#023E65" }}>
            Only INP and .dat files accepted
          </span>
        </div>
        <div className="tableContainer mt-3 flex column w-full">
          <table
            style={{
              border: "2px solid #E6E6E6",
              height: "calc(100vh - 340px)",
              borderRadius: "10px",
              borderCollapse: "separate",
            }}
          >
            <tr style={{ height: "50px" }}>
              <th
                style={{
                  paddingLeft: "20px",
                  borderBottom: "2px solid #E6E6E6",
                }}
              >
                File Name
              </th>
              <th
                style={{
                  borderBottom: "2px solid #E6E6E6",
                }}
              >
                Size
              </th>
              <th
                style={{
                  borderBottom: "2px solid #E6E6E6",
                }}
              >
                Location
              </th>
              <th
                style={{
                  borderBottom: "2px solid #E6E6E6",
                }}
              >
                Model Type
              </th>
              <th
                style={{
                  borderBottom: "2px solid #E6E6E6",
                }}
              ></th>
            </tr>
            {inpFile.length === 0 && datFiles.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <Dragger
                    {...initialUploadProps}
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="flex column" style={{}}>
                      <span className="mb-2" style={{ color: "#74788D" }}>
                        Drag and drop your files here or click in
                      </span>
                      {/* <Upload {...uploadProps}> */}
                      <Button
                        icon={<PlusCircleOutlined rev={undefined} />}
                        shape="round"
                        className="flex items-center self-center"
                        style={{
                          color: "#023E65",
                          borderColor: "#023E65",
                          backgroundColor: "transparent",
                        }}
                      >
                        Add Files
                      </Button>
                      {/* </Upload> */}
                    </div>
                  </Dragger>
                </td>
              </tr>
            ) : (
              <>
                {inpFile.length > 0 && (
                  <tr style={{ height: "40px" }}>
                    <td style={{ paddingLeft: "20px" }}>{inpFile[0].name}</td>
                    <td>{fileSize(inpFile[0].size)}</td>
                    <td className="pr-2">
                      <Locations
                        locations={locations}
                        handleClickSelect={handleClickLocation}
                        defaultValue={false}
                      />
                    </td>
                    <td className="pr-2">
                      <Select
                        defaultValue="WATER"
                        style={{ width: "100%", borderRadius: "8px" }}
                        onChange={(val) => {
                          setSelectedModelType(val);
                        }}
                        options={[
                          { value: "WATER", label: "WATER" },
                          { value: "DRAINAGE", label: "DRAINAGE" },
                        ]}
                      />
                    </td>
                    <td>
                      <DeleteOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "1.3rem",
                        }}
                        onClick={() => {
                          deleteInp();
                        }}
                        rev={undefined}
                      />
                    </td>
                  </tr>
                )}
                {datFiles.map((df) => {
                  return (
                    <tr
                      style={{
                        height: "40px",
                        backgroundColor: "#F7F9FC",
                      }}
                      key={df.uid}
                    >
                      <td style={{ paddingLeft: "50px" }}>{df.name}</td>
                      <td>{fileSize(df.size)}</td>
                      <td>
                        <Select
                          mode="multiple"
                          allowClear
                          options={pluviometers.map((pluv) => {
                            return {
                              value: pluv,
                              label: pluv,
                            };
                          })}
                          placeholder={"Select"}
                          style={{ width: "140px" }}
                          onChange={(value) => {
                            const newArray = [...selectedPluviometers];
                            const findObject = newArray.find(
                              (pluvi) => pluvi.uid === df.uid
                            );
                            if (findObject) {
                              findObject.value = value.join(",");
                              setselectedPluviometers(newArray);
                            } else {
                              setselectedPluviometers([
                                ...selectedPluviometers,
                                {
                                  uid: df.uid,
                                  value: value.join(","),
                                },
                              ]);
                            }
                          }}
                        />
                      </td>
                      <td></td>
                      <td>
                        <DeleteOutlined
                          style={{
                            cursor: "pointer",
                            fontSize: "1.3rem",
                          }}
                          onClick={() => {
                            deleteDat(df.uid);
                          }}
                          rev={undefined}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={5}>
                    <Dragger
                      {...initialUploadProps}
                      style={{ backgroundColor: "white" }}
                    >
                      <Button
                        icon={
                          <PlusCircleOutlined
                            style={{
                              fontSize: "1.4rem",
                            }}
                            rev={undefined}
                          />
                        }
                        shape="round"
                        style={{
                          width: "50px",
                          height: "50px",
                          color: "#023E65",
                          border: "2px solid #023E65",
                          bottom: "10px",
                          right: "10px",
                        }}
                        className="flex items-center justify-center absolute"
                      />
                    </Dragger>
                  </td>
                </tr>
              </>
            )}
          </table>
        </div>
        <div className="flex pt-3 justify-between">
          <Button
            style={{ width: "85px", height: "40px" }}
            onClick={() => {
              navigate(`/upload?selectedTab=MODEL`);
            }}
          >
            Cancel
          </Button>
          <div>
            {/* <Button
              className="mr-2"
              disabled={inpFile.length < 0}
              style={{
                width: "100px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              onClick={() => {
                goToEdditFile();
              }}
            >
              Edit Model
            </Button> */}
            <Button
              style={{
                width: "120px",
                height: "40px",
                backgroundColor: "#00203C",
                color: "white",
              }}
              onClick={() => {
                uploadFiles();
              }}
            >
              Upload Model
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapState(state: { locations: { array: any } }) {
  return {
    locations: state.locations.array,
  };
}

export default connect(mapState)(UploadModelFiles);
