import React, { useContext, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { VictoryChart, VictoryArea, VictoryAxis } from "victory";
import ReactECharts from "echarts-for-react";
import ModalCharts from "../../components/ModalCharts";
import ReactDOM from "react-dom";
import axios from "axios";
import "./popup.css";
import { useEffect } from "react";
import cloneDeep from "lodash.clonedeep";
import { last } from "underscore";
import { connect, useDispatch } from "react-redux";
import UpdateIconSvg from "../../assets/svg/UpdateIconSvg";
import { Tooltip } from "antd";
import Icon, { CloseCircleOutlined } from "@ant-design/icons";
import DashboardContext from "../../layout/DashboardLayout/context";
import { setShowLoader } from "../../redux/loaderDuck";
import InfrastructureService from "../../domain/Infrastructure/InfraestructureService";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { lateralMenuEventsService } from "../../events/lateralMenu.subject";

const data = [
  { x: 1, y: 0 },
  { x: 2, y: 3 },
  { x: 3, y: 3 },
  { x: 5, y: 1 },
  { x: 4, y: 4 },
  { x: 6, y: 5 },
  { x: 7, y: 5.1 },
  { x: 8, y: 5.2 },
  { x: 9, y: 5.3 },
  { x: 9.5, y: 5.4 },
  { x: 10, y: 10 },
  { x: 11, y: 20 },
];

const infrastructureService = new InfrastructureService();

function Popup(props) {
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const [selectedSensor, setSelectSensor] = useState(1);
  const [viewMore, setViewMore] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [valuesModal, setValueModal] = useState([]);
  const [list, setList] = useState([]);
  const [widthPop, setWidthPop] = useState(200);

  const [isLoading, setLoading] = useState(true);

  const [dataModal, setDataModal] = useState([]);
  const [hcOptions, setHcOptions] = useState(null);

  const dataConvertida = [];

  const DEFAULT_OPTION = {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "time",
      axisLabel: {
        inside: true,
        color: "#fff",
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        inside: true,
        color: "#fff",
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    series: dataModal,
  };

  const [option, setOption] = useState(DEFAULT_OPTION);

  let hoy = new Date();
  let hora = 43200000;
  let fec_init = formatDate(new Date(hoy.getTime()));
  let fec_end = formatDate(new Date(hoy.getTime() - hora));

  // const [setData, data] = useState();

  const dataList = [];

  function dataConvert(historicalObject, valueUnit) {
    const dataConvert = [];
    const historical = Object.keys(historicalObject);
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;

    historical.forEach((key) => {
      dataConvert.push([Number(key), historicalObject[key].value, valueUnit]);
    });
    console.log(
      "🚀 ~ file: Popup.js:111 ~ historical.forEach ~ dataConvert:",
      dataConvert
    );

    return dataConvert;
  }

  const checkEventOnMenu = (name, event) => {
    var element1 = document.querySelector('[aria-label="' + name + '"]');
    var element2 = document.querySelector('[aria-label="' + name + '"] > div');
    var element3 = document.querySelector(
      '[aria-label="' + name + '"] > div > div'
    );
    var element4 = document.querySelector(
      '[aria-label="' + name + '"] > div > div > div > label > span > input'
    );

    if (event) {
      element1.ariaSelected = true;
      element2.ariaSelected = true;
      element3.classList.add("rs-checkbox-checked");
      element4.setAttribute("aria-checked", true);
    } else {
      element1.ariaSelected = false;
      element2.ariaSelected = false;
      element3.classList.remove("rs-checkbox-checked");
      element4.setAttribute("aria-checked", false);
    }
  };

  const checkEvent = (event, item) => {
    let selectedSensors = localStorage.getItem("selectedSensors")
      ? JSON.parse(localStorage.getItem("selectedSensors"))
      : [];
    checkEventOnMenu(item.measurementTargetName, event);
    if (event) {
      selectedSensors.push(
        JSON.stringify({
          measurementTargetId: item.measurementTargetId,
          measurementTargetName: item.measurementTargetName,
          serialNumberMeasurementTool: item.serialNumberMeasurementTool,
        })
      );
      localStorage.setItem("selectedSensors", JSON.stringify(selectedSensors));
    } else {
      const index = selectedSensors.indexOf(
        JSON.stringify({
          measurementTargetId: item.measurementTargetId,
          measurementTargetName: item.measurementTargetName,
          serialNumberMeasurementTool: item.serialNumberMeasurementTool,
        })
      );
      if (index > -1) {
        selectedSensors.splice(index, 1);
      }
      localStorage.setItem("selectedSensors", JSON.stringify(selectedSensors));
    }
  };

  const verifyChecked = (item) => {
    const selectedSensors = localStorage.getItem("selectedSensors")
      ? JSON.parse(localStorage.getItem("selectedSensors"))
      : [];
    if (selectedSensors.length <= 0) {
      document.getElementById("chart").style.display = "none";
    }
    const exist = selectedSensors.some((sensor) => {
      return JSON.parse(sensor).measurementTargetId == item.measurementTargetId;
    });
    if (exist) return "checked";
    return "";
  };

  function checkBoxValue(id) {
    // if (id) {
    //   setOpenPreview(true);
    //   setValueModal([...valuesModal, id]);
    //   setOpenModal(true);
    // }
    document.getElementById("chart").style.display = "block";
  }

  function formatDate(date) {
    var dateFormat;
    var timeFormat;

    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes(),
      seconds = d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    dateFormat = [year, month, day].join("-");
    timeFormat = [hour, minute, seconds].join(":");

    return dateFormat + " " + timeFormat;
  }

  useEffect(() => {
    fetchNewData();
  }, []);

  const fetchNewData = () => {
    let access_token = localStorage.getItem("accessToken");
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/historical/" +
          props.data.measurementTargetId +
          "/readings?dateFrom=" +
          dayjs().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss") +
          // "2022-09-27 6:15:15" +
          "&dateTo=" +
          dayjs().format("YYYY-MM-DD HH:mm:ss"),
        // "2022-09-29 6:15:15",
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        // const [dateFrom, dataTo] = context.selectedDate;
        const dateFrom = dayjs()
          .subtract(1, "day")
          .format("YYYY-MM-DD HH:mm:ss");
        const dataTo = dayjs().format("YYYY-MM-DD HH:mm:ss");

        const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const dataArray = [res.data];

        let yAxis = [];
        dataArray.forEach((item) => {
          if (item === undefined) return;
          if (!yAxis.find(({ valueUnit }) => valueUnit === item.valueUnit)) {
            yAxis.push({
              valueUnit: item.valueUnit,
              labels: {
                format: `{value}${item.valueUnit}`,
              },
              title: {
                text: `${item.sensorType}`,
              },
            });
          }
        });
        let seriesData = [];
        dataArray.forEach((data, index) => {
          if (data === undefined) return;
          const measurementHistorialValues =
            data.measurementToolHistoricalValues;
          if (measurementHistorialValues) {
            const sources = Object.keys(measurementHistorialValues)
              .map((item) => {
                return measurementHistorialValues[item].serialNumber;
              })
              .filter((item) => item != null);
            const uniqueSources = [...new Set(sources)];
            if (uniqueSources.length > 0) {
              uniqueSources.forEach((source) => {
                if (!source) return;
                const keys = Object.keys(measurementHistorialValues);
                const dataSeries = keys
                  .filter(
                    (item) =>
                      measurementHistorialValues[item].serialNumber == source
                  )
                  .map((item) => [
                    parseFloat(item),
                    measurementHistorialValues[item].value,
                  ])
                  .sort((a, b) => {
                    return a[0] - b[0];
                  });
                const read = {
                  name: data.measurementTargetName,
                  sourceName: source,
                  valueUnit: data.valueUnit,
                  sensorType: data.sensorType,

                  yAxis: yAxis.findIndex(
                    ({ valueUnit }) => valueUnit === data.valueUnit
                  ),
                  data: dataSeries,
                };
                seriesData.push(read);
              });
            } else {
              const dateFrom = dayjs()
                .subtract(1, "day")
                .format("YYYY-MM-DD HH:mm:ss");
              const dataTo = dayjs().format("YYYY-MM-DD HH:mm:ss");
              const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
              const read = {
                name: data.measurementTargetName,
                sourceName: "",
                valueUnit: data.valueUnit,
                sensorType: data.sensorType,
                yAxis: yAxis.findIndex(
                  ({ valueUnit }) => valueUnit === data.valueUnit
                ),
                data: [
                  [new Date(dateFrom).getTime() - localOffset, null],
                  [new Date(dataTo).getTime() - localOffset, null],
                ],
              };
              seriesData.push(read);
            }
          } else {
            const dateFrom = dayjs()
              .subtract(1, "day")
              .format("YYYY-MM-DD HH:mm:ss");
            const dataTo = dayjs().format("YYYY-MM-DD HH:mm:ss");
            const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
            const read = {
              name: data.measurementTargetName,
              sourceName: "",
              valueUnit: data.valueUnit,
              sensorType: data.sensorType,
              yAxis: yAxis.findIndex(
                ({ valueUnit }) => valueUnit === data.valueUnit
              ),
              data: [
                [new Date(dateFrom).getTime() - localOffset, null],
                [new Date(dataTo).getTime() - localOffset, null],
              ],
            };
            seriesData.push(read);
          }
          if (data.measurementToolForecastValues) {
            data.measurementToolForecastValues?.forEach((forecast) => {
              const colors = Highcharts.getOptions().colors;
              if (!colors) return;
              const foreData = {
                name: data.measurementTargetName,
                sourceName: data.serialNumberTool,
                valueUnit: data.valueUnit,
                sensorType: data.sensorType,
                yAxis: yAxis.findIndex(
                  ({ valueUnit }) => valueUnit === data.valueUnit
                ),
                showInLegend: false,
                color: colors[index],
                dashStyle: "Dash",
                data: forecast
                  ? Object.keys(forecast)
                      .map((item) => [parseFloat(item), forecast[item]])
                      .sort((a, b) => {
                        return a[0] - b[0];
                      })
                  : [],
              };
              seriesData.push(foreData);
            });
          }
        });

        setHcOptions({
          title: {
            text: "",
          },
          credits: {
            enabled: false,
          },
          chart: {
            type: "line",
            zoomType: "x",
            panKey: "shift",
            height: 220,
            plotBorderWidth: 1,
            backgroundColor: "#FFFFFF",
            events: {
              load: function () {
                const vm = this;
                // setHighchartVmRef(this);
              },
            },
          },
          legend: {
            backgroundColor: "#FFFFFF",
            enabled: false,
          },
          xAxis: {
            type: "datetime",
            // plotBands: [
            //   {
            //     from: new Date() - localOffset,
            //     to: new Date(dataTo),
            //     color: "#EFFFFF",
            //   },
            // ],
            // min: dayjs().subtract(1, "day").valueOf(),
            // max: dayjs().valueOf() - localOffset,
            ordinal: false,
            events: {
              click: (event) => {},
            },
          },
          yAxis: yAxis,
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },
          navigator: {
            enabled: false,
          },
          tooltip: {
            formatter: function () {
              let text = ``;
              this.points.forEach((point) => {
                const series = point.series;
                const symbol = "●";
                text += `<span style="color:${series.color}">${symbol} ${series.name}</span>: <b style="color: black">${point.y} ${series.userOptions.valueUnit}</b><br/><span style="color: black"> Source: <b>${series.userOptions.sourceName}</b></span><br/>`;
              });
              return [
                `<span style="color: black">${dayjs(
                  this.points[0].x + localOffset
                ).format("YYYY-MM-DD hh:mm:ss A")}</span><br/><br/>`,
                text,
              ];
            },
            valueDecimals: 2,
            shared: true,
            xDateFormat: "%Y-%m-%d %H:%M:%S",
          },
          rangeSelector: {
            buttonTheme: {
              style: {
                display: "none",
              },
            },
            enabled: false,
            dropdown: "always",
            inputDateFormat: "%Y/%m/%d %I:%M %p",
            selected: 1,
          },
          series: seriesData,
        });
        console.log(hcOptions);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  function viewSensors() {
    setContext({
      ...context,
      expandLeftMenu: true,
      infraToShow: {
        id: props.id,
        name: props.data.measurementInfraestructureName,
      },
    });
  }

  if (selectedSensor) {
    return (
      <div style={{ margin: "1rem" }}>
        <Tooltip title="Close">
          <CloseCircleOutlined
            className="cursor-pointer"
            style={{ position: "absolute", top: 5, right: 5, fontSize: "20px" }}
            onClick={props.onClose}
          />
        </Tooltip>
        <div className="popupTitle">
          <h5 className="mr-2">{props.data.measurementInfraestructureName}</h5>{" "}
          -
          {/* <Tooltip
            placement="right"
            title="Update last 24 hours"
            color="#023E65"
          >
            <Icon component={UpdateIconSvg} style={{ cursor: "pointer" }} />
          </Tooltip> */}
          <span
            style={{
              color: "#74788D",
              fontSize: "10px",
              marginTop: "2px",
              paddingLeft: "10px",
            }}
          >
            {`${props.data.infrastructureTypeName}`}
          </span>
        </div>
        <div className="popupBody">
          <div
            className="flex"
            style={{ minWidth: "550px", columnGap: "30px" }}
          >
            <div className="flex column" style={{ width: "50%" }}>
              <div
                className="flex items-center pl-2 mb-2"
                style={{ backgroundColor: "#F7F9FC", borderRadius: "8px" }}
              >
                <b>{props.data.measurementTargetName}</b>
              </div>
              <div className="flex" style={{ minWidth: "305px" }}>
                <div className="col-6" style={{ padding: "0 0 0 12px" }}>
                  <div className="mb-2">Current Value:</div>
                  <div className="mb-2">Max. Value:</div>
                  <div className="mb-2">Min. Value:</div>
                  <div className="mb-2">Average Value:</div>
                </div>
                <div className="col-6">
                  <div className="mb-2">
                    {props.data.currentValue >= 0
                      ? props.data.currentValue + " " + props.data.unit
                      : "No Data"}
                  </div>
                  <div className="mb-2">
                    {props.data.maxValue >= 0
                      ? props.data.maxValue + " " + props.data.unit
                      : "No Data"}
                  </div>
                  <div className="mb-2">
                    {props.data.minValue >= 0
                      ? props.data.minValue + " " + props.data.unit
                      : "No Data"}
                  </div>
                  <div className="mb-2">
                    {props.data.averageValue >= 0
                      ? props.data.averageValue + " " + props.data.unit
                      : "No Data"}
                  </div>
                </div>
              </div>
              <div className="">
                <span
                  onClick={() => {
                    lateralMenuEventsService.setSubject({
                      type: "setActive",
                      data: "INFRASTRUCTURE",
                    });
                    viewSensors();
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    color: "#023E65",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  View all parameters
                </span>
              </div>
            </div>
            <div className="flex column popupTarget" style={{ width: "50%" }}>
              <div
                className="row"
                style={{ height: "100%", backgroundColor: "#F7F9FC" }}
              >
                {/* <ReactECharts
                  style={{
                    height: "100%",
                  }}
                  option={option}
                  showLoading={isLoading}
                /> */}
                {hcOptions && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={hcOptions}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div id="viewMore" className="viewMore">
          <div className="viewMoreTitle">Current Readings</div>
          <div className="viewContent">
            <table className="table table-hover table-borderless">
              <thead>
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Type</th>
                  <th>Critical Low</th>
                  <th>Critical High</th>
                  <th>Reading</th>
                  <th>Utilization</th>
                  <th>Last Collection</th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0
                  ? list.map((viewMore) => (
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            name="radio"
                            id="radio"
                            defaultChecked={verifyChecked(viewMore)}
                            onClick={(e) => {
                              checkEvent(e.target.checked, viewMore);
                              checkBoxValue(viewMore.measurementTargetId);
                            }}
                          />
                        </td>
                        <td className="body-popup">
                          {viewMore.measurementTargetName}
                        </td>
                        <td className="body-popup">
                          {viewMore.measurementTargetType}
                        </td>
                        <td className="body-popup">
                          {viewMore.lcritical + " " + viewMore.unit}
                        </td>
                        <td className="body-popup">
                          {viewMore.hcritical + " " + viewMore.unit}
                        </td>
                        <td className="body-popup">
                          {viewMore.currentValue + " " + viewMore.unit}
                        </td>
                        <td className="body-popup">{viewMore.usage}%</td>
                        <td className="body-popup">{viewMore.lastCollected}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        {openPreview &&
          ReactDOM.render(
            // <ModalCharts
            //     closeModal={setOpenModal}
            //     selectedData={valuesModal}
            //     type={openPreview}
            // />,
            document.getElementById("chart")
          )}
      </div>
    );
  }
}

export default Popup;
